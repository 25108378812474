<template>
  <div class="gift-content pd-10 h-calc-60">
<!--      <div class="el-breadcrumb-c">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><b>礼品管理</b></el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
          </el-breadcrumb>
      </div>-->
      <div class="gift-form">
          <el-form label-width="120px" ref="giftFormRef" :model="giftForm" :rules="rules">
              <el-form-item label="礼品名称" prop="gift_name">
                  <el-input v-model="giftForm.gift_name" />
              </el-form-item>
              <el-form-item label="兑换积分" prop="points">
                  <el-input v-model.number="giftForm.points" />
              </el-form-item>
              <el-form-item label="礼品主图">
                  <input type="file" name="" id="" style="display: none;" ref="imgRef" value="" @change="uploadImg">
                  <div class="upload-img-area" @click="uploadImgEvt" v-if="!giftForm.gift_img">
                      <span style="font-size: 25px; color: #333;"><el-icon><Plus /></el-icon></span>
                  </div>
                  <div class="upload-img-cover" v-else>
                      <img :src="giftForm.gift_img" :alt="giftForm.gift_name">
                      <div class="gift-img-preview">
                          <span style="font-size: 25px; color: #FFF;" @click="handleRemove">
                              <el-icon><Delete /></el-icon>
                          </span>

                      </div>
                  </div>
              </el-form-item>
              <el-form-item label="礼品描述" prop="description">
                  <el-input type="textarea" v-model="giftForm.description" rows="3" :show-word-limit="true" maxlength="255" />
              </el-form-item>
              <el-form-item label="是否开启">
                  <el-switch v-model="status" />
              </el-form-item>
              <el-form-item>
                  <el-button @click="resetForm(giftFormRef)">重置</el-button>
                  <el-button type="primary" @click="submitForm(giftFormRef)">保存</el-button>
              </el-form-item>
          </el-form>
      </div>

  </div>
</template>

<script setup>
import {ref, reactive, onMounted} from 'vue'
import {useRoute,useRouter} from "vue-router";
import {Delete, Plus} from "@element-plus/icons-vue";
import {uploadGiftImg, giftAdd, giftInfo} from "@/utils/apis"
import {ElMessage} from "element-plus";
const route = useRoute()
const router = useRouter()
const giftForm = reactive({
    gift_name: '',
    points: '',
    gift_img: '',
    description: '',
    status: false
})
const rules = reactive({
    gift_name: [
      {required: true, message: '礼品名称不能为空', trigger: 'blur'},
      {min: 1, max: 200, message: '礼品名称在1-200个字数之间', trigger: ''}
    ],
    points: [
      {required: true, message: '兑换积分不能为空', trigger: 'blur'},
    ],
    description: [
      {required: true, message: '描述不能为空', trigger: 'blur'},
    ],
})
const giftFormRef = ref()
const imgRef = ref()
const title = ref("创建礼品")
const id = ref(0)
const handleRemove = () => {
    giftForm.gift_img = ""
}
const status = ref(false)

const uploadImgEvt = () => {
  imgRef.value.click()
}
const uploadImg = async (e) => {
    try {
      const file = e.target.files[0];
      console.log('e',file);
      let formdata = new FormData()
      formdata.append('gift_img', file)
      const {data, msg} = await uploadGiftImg(formdata)
      ElMessage.success(msg)
      giftForm.gift_img = data.url;
    } catch (e) {
        ElMessage.warning(e.message)
    }
}
const resetForm = (formEle) => {
    if (!formEle) return
    formEle.resetFields()
    giftForm.gift_img = ""
}
const submitForm = async (formEle) => {
    if (!formEle) return
    await formEle.validate((valid, fields) => {
        if (valid) {
            if (!giftForm.gift_img) return ElMessage.warning('请上传礼品主图')
            giftAddForm(giftForm)
        } else {
           console.log('submit error', fields)
        }
    })
}
const giftAddForm = async (form) => {
    try {
        form.status = status.value ? 1: 0;
        if (route.query.id) {
          form.gift_id = parseInt(id.value)
        }
        const {data, msg} = await giftAdd(form)
        ElMessage.success(msg)
        router.push({name: 'GiftIndex'})
    }catch (e) {
        ElMessage.warning(e.message)
    }
}
const giftDetail = async (id) => {
    try {
        const {data, msg} = await giftInfo(id)
        Object.assign(giftForm, {...data})
        status.value = parseInt(giftForm.status) === 1
    } catch (e) {
        ElMessage.warning(e.message)
    }
}
onMounted(() => {
  if (route.query.id) {
    id.value = parseInt(route.query.id)
    title.value = '编辑礼品'
    giftDetail(id.value)
  }
})




</script>

<style scoped lang="scss">
.gift-content {
  &, & * {
      box-sizing: border-box;
  }
  .el-breadcrumb-c {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      padding-left: 20px;
      background-color: #edf2f9;
      border-radius: 5px;
      &:deep(.el-breadcrumb) {
           .el-breadcrumb__inner {
               color: #5e6e82;
           }
        }

  }
  .gift-form {
      width: 700px;
      .upload-img-area, .upload-img-cover {
          width: 150px;
          height: 150px;
          border-radius: 20px;
          border: 1px dashed #cdd0d6;
          background-color: #fafafa;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
      }
      .upload-img-cover {
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          .gift-img-preview {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              span {
                  display: none;
              }
              &:hover {
                  background-color: rgba(0, 0, 0, .3);
                  & span {
                    display: inline-block;
                  }
              }
          }
      }
  }
}
</style>